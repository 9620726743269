<template>
    <v-card>
        <v-card-title>
            {{ $t('contacts.headline') }}
        </v-card-title>
        <v-card-text>
            <v-layout>
                <v-row>
                    <v-col cols="12" sm="6" lg="3">
                        <v-select
                            v-model="selectedUserRole"
                            :items="userRoles"
                            :label="$t('users.list.roleFilter')"
                            :multiple="false"
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col cols="12" sm="6" lg="4">
                        <v-select
                            v-model="selectedMarketSegment"
                            :items="translatedSegments"
                            :label="$t('product.segment.label')"
                            :multiple="false"
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col cols="12" lg="5">
                        <v-select
                            v-model="selectedProductGroup"
                            :items="productGroups"
                            :label="$t('product.productGroupWebsite.label')"
                            :multiple="false"
                            hide-details
                            clearable
                        />
                    </v-col>
                </v-row>
            </v-layout>
        </v-card-text>
        <v-data-table
            :headers="headers"
            :items="filteredUsers"
            :loading="loading"
            :loading-text="$t('users.list.loading')"
            :no-data-text="$t('users.list.noDataFound')"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
            class="elevation-1 users-list"
        >
            <template #item.email="{ item }">
                <a v-if="item.email" :href="`mailto:${item.email}`">{{ item.email }}</a>
                <span v-else v-t="'common.n/a'" />
            </template>
            <template #item.productGroupWebsite="{ item }">
                <span>{{ item.productGroupWebsite ? item.productGroupWebsite : '-' }}</span>
            </template>
            <template #item.segment="{ item }">
                <span>{{ item.segment ? item.segment : '-' }}</span>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { extractAndDisplayError, handleFetchResponse } from '@/utils'

export default {
    name: 'Contacts',
    data: function () {
        return {
            selectedUserRole: null,
            selectedProductGroup: null,
            selectedMarketSegment: null,
            loading: false,
            users: [],
            headers: [
                { text: this.$t('users.name.label'), value: 'name' },
                { text: this.$t('users.email.label'), value: 'email' },
                { text: this.$t('users.role.label'), value: 'role' },
                { text: this.$t('users.productGroup.label'), value: 'productGroupName' },
                { text: this.$t('users.segment.label'), value: 'marketSegmentName' }
            ],
            userRoles: [
                this.$t('users.roles.Administrator'),
                this.$t('users.roles.SAP Expert'),
                this.$t('users.roles.Global technical expert'),
                this.$t('users.roles.Marketing expert'),
                this.$t('users.roles.Market Segment expert'),
                this.$t('users.roles.Communication expert'),
                this.$t('users.roles.Regulatory expert')
            ]
        }
    },
    computed: {
        ...mapState('product', ['marketSegments', 'productGroups']),
        translatedSegments() {
            return this.marketSegments.map(seg => {
                return {
                    value: seg.text,
                    text: this.$t(`product.marketSegments.${seg.text}`)
                }
            })
        },
        areFiltersApplied() {
            return !!(this.selectedUserRole || this.selectedProductGroup || this.selectedMarketSegment)
        },
        filteredUsers() {
            return this.areFiltersApplied ? this.users.filter(this.matchesUserCurrentSearch) : this.users
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        matchesUserCurrentSearch(user) {
            if (this.selectedUserRole && user.role !== this.selectedUserRole) {
                return false
            }
            if (this.selectedProductGroup && user.productGroupValue !== this.selectedProductGroup) {
                return false
            }
            if (this.selectedMarketSegment && user.marketSegmentValue !== this.selectedMarketSegment) {
                return false
            }
            return true
        },
        loadData() {
            this.loading = true
            fetch('/api/users?size=1000')
                .then(handleFetchResponse)
                .then(data => {
                    this.users = data._embedded.users.reduce((users, user) => {
                        user.roleAssignments.forEach(r => {
                            const { role, validity } = r
                            const u = {
                                name: `${user.firstName} ${user.lastName}`,
                                email: user.email,
                                role: role.name,
                                productGroupName: '-',
                                productGroupValue: null,
                                marketSegmentName: '-',
                                marketSegmentValue: null
                            }
                            if (validity.productGroup) {
                                u.productGroupValue = validity.productGroup.backendName
                                u.productGroupName = validity.productGroup.officialName
                            }
                            if (validity.marketSegment) {
                                u.marketSegmentValue = validity.marketSegment.name
                                u.marketSegmentName = this.$t(`product.marketSegments.${validity.marketSegment.name}`)
                            }
                            users.push(u)
                        })
                        return users
                    }, [])
                })
                .catch(err => extractAndDisplayError(err, this.$noty))
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>
